import React from 'react';


const Popup = () => {
    return (
        <div>
            <div className="popup">
            <div class="dot-spinner">
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
</div>
                <h2>Hold on, we are checking...</h2>
            </div>


        </div>
    );
}

export default Popup;
